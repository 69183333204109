import React from "react"
import PageHeader from "../../components/PageHeader/PageHeader"
import NavPages from "../../components/NavPages/NavPages"
import Transition from "../../components/Generator/Transition/Transition"
import Seo from "../../components/Seo/Seo"
import Footer from "../../components/Footer/Footer"

const TransitionPage = ({ location }) => {
  const language = location.pathname.substr(1, 2)
  return (
    <>
      <Seo
        title="CSS Transition generator | CSSGENERATOR.PL"
        description="CSS TRANSITION GENERATOR | An easy to use tool that allows you to generate ready CSS rules. Adjust values and copy the finished code."
        location={location}
        language={language}
        hreflang
      />
      <PageHeader
        h1="Transition Generator"
        desc="An easy to use tool that allows you to generate ready CSS rules. Adjust transition values and copy the ready code."
        language={language}
      />
      <NavPages language={language} />
      <Transition language={language} />
      <Footer language={language} />
    </>
  )
}

export default TransitionPage
